import { CompareTypeItem } from "../interfaces/CompareTypeItem";

export class AddTypeToCompare {
    static readonly type = '[Type Study] Add Type to Compare';
    constructor(public payload: CompareTypeItem) { }
}

export class RemoveTypeFromCompare {
    static readonly type = '[Type Study] Remove Type from Compare';
    constructor(public payload: string) { }
}

export class ClearCompareList {
    static readonly type = '[Type Study] Clear Compare List';
}

export class ReorderTypeCompareItem {
    static readonly type = '[Type Study] Reorder Type in the Compare List';
    constructor(public payload: { previousIndex: number, currentIndex: number }) { }
}

export class GetComparedTypes {
    static readonly type = '[Type Study] Get Compare Types';
}