import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, catchError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { User } from 'src/app/user/interfaces/User';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private snackBar: SnackbarService) { }

  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
        this.snackBar.error(error?.error?.message);
    }
    // Return an observable with a user-facing error message.
    return of();
  }

  public login(username: string, password: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'accept': 'application/json' }),
      withCredentials: true
    }
    
    return this.http.post(`${environment.userApi}/Login`, { username, password }, httpOptions).pipe(catchError(this.handleError));
  }


  public logout(): Observable<null> {
    const httpOptions = {
      headers: new HttpHeaders({ 'accept': 'application/json' }),
      withCredentials: true
    }

    return this.http.post<null>(`${environment.userApi}/Logout`, {}, httpOptions);
  }


  public getUser(): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'accept': 'application/json'
      }),
      withCredentials: true
    }

    return this.http.get<User>(`${environment.userApi}/GetCurrentUser`, httpOptions).pipe();
  }



}
