import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, find, map, Observable, of, share } from 'rxjs';

import { TypeData } from '../interfaces/TypeData';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TypeInfoService {

    private httpOptions = {
        headers: new HttpHeaders({ 'accept': 'application/json' }),
        withCredentials: true
    };

    constructor(private http: HttpClient) { }

    public getListOfTypes(): Observable<{ id: number, type: string }[]> {
        return this.http.get<{ id: number, type: string }[]>(`${environment.typeDataApi}/GetTypesList`, this.httpOptions)
            .pipe(share());
    }

    public getTypeListInfo(): Observable<TypeData[]> {
        return this.http.get<TypeData[]>(`${environment.typeDataApi}/GetTypeData`, this.httpOptions)
            .pipe(share());
    }

    public getTypeInfoByTypes(types: string[]): Observable<TypeData[]> {
        return this.http.post<TypeData[]>(`${environment.typeDataApi}/GetTypeDataByTypes`, { types: types }, this.httpOptions)
            .pipe(share());
    }

    public getTypeInfo(type: string): Observable<TypeData | null> {
        return this.http.get<TypeData>(`${environment.typeDataApi}/GetTypeDataByType/${type}`, this.httpOptions)
            .pipe(share());
    }

    //     private TYPE_DATA: TypeData[] = JSON.parse(`[
    //     {
    //         "id": 1,
    //         "type": "1",
    //         "prodStart": 1884,
    //         "prodEnd": 1885,
    //         "weight": null,
    //         "hasImage": true,
    //         "description": "First production type Stanley #45. Black japanned plane with floral castings and the knob on the main body.",
    //         "features": [
    //             {
    //                 "id": 1,
    //                 "name": "Black Japanning",
    //                 "description": "The cast iron parts are coated with black japanning finish."
    //             },
    //             {
    //                 "id": 2,
    //                 "name": "Floral Castings",
    //                 "description": "The cast iron parts have floral design patterns."
    //             },
    //             {
    //                 "id": 3,
    //                 "name": "Knob on Body",
    //                 "description": "The front knob for the plane is attached to the front portion of the main plane casting."
    //             },
    //             {
    //                 "id": 4,
    //                 "name": "Solid Brass Thumbscrews",
    //                 "description": "The thumb screws are solid brass with knurled round heads.  They do not have any slots in them."
    //             }
    //         ]
    //     },
    //     {
    //         "id": 2,
    //         "type": "2",
    //         "prodStart": 1886,
    //         "prodEnd": 1887,
    //         "weight": null,
    //         "hasImage": true,
    //         "description": "Second production type Stanley #45. Black japanned plane with floral castings and the knob on the main body. Added '45' and 'Stanley' to the fence casting.",
    //         "features": [
    //             {
    //                 "id": 1,
    //                 "name": "Black Japanning",
    //                 "description": "The cast iron parts are coated with black japanning finish."
    //             },
    //             {
    //                 "id": 2,
    //                 "name": "Floral Castings",
    //                 "description": "The cast iron parts have floral design patterns."
    //             },
    //             {
    //                 "id": 3,
    //                 "name": "Knob on Body",
    //                 "description": "The front knob for the plane is attached to the front portion of the main plane casting."
    //             },
    //             {
    //                 "id": 5,
    //                 "name": "Slotted Brass Thumbscrews",
    //                 "description": "The thumb screws are made from brass with knurled round heads.  They have flat head screw slots cut in them."
    //             },
    //             {
    //                 "id": 6,
    //                 "name": "Stanley 45 Cast Fence",
    //                 "description": "The fence has '45' and 'Stanley' cast in to it at the toe and heal, respectively."
    //             }
    //         ]
    //     },
    //     {
    //         "id": 3,
    //         "type": "3",
    //         "prodStart": 1887,
    //         "prodEnd": 1888,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "Third production type Stanley #45. Black japanned plane with floral castings and the knob on the main body. Added a flat section to the fence casting.",
    //         "features": [
    //             {
    //                 "id": 1,
    //                 "name": "Black Japanning",
    //                 "description": "The cast iron parts are coated with black japanning finish."
    //             },
    //             {
    //                 "id": 2,
    //                 "name": "Floral Castings",
    //                 "description": "The cast iron parts have floral design patterns."
    //             },
    //             {
    //                 "id": 3,
    //                 "name": "Knob on Body",
    //                 "description": "The front knob for the plane is attached to the front portion of the main plane casting."
    //             },
    //             {
    //                 "id": 5,
    //                 "name": "Slotted Brass Thumbscrews",
    //                 "description": "The thumb screws are made from brass with knurled round heads.  They have flat head screw slots cut in them."
    //             },
    //             {
    //                 "id": 6,
    //                 "name": "Stanley 45 Cast Fence",
    //                 "description": "The fence has '45' and 'Stanley' cast in to it at the toe and heal, respectively."
    //             },
    //             {
    //                 "id": 7,
    //                 "name": "Fence Flat Spot",
    //                 "description": "The fence has a flat spot cast in to the middle of the outward face of the fence.  This was useddone so that the fence could bef lipped around and used as a registration surface to extend the reach of the plane."
    //             }
    //         ]
    //     },
    //     {
    //         "id": 4,
    //         "type": "4",
    //         "prodStart": 1890,
    //         "prodEnd": 1892,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "Fourth production type Stanley #45. Nickel plated plane and screws with floral castings and the knob on the main body. Added nickel plating on castings and screws.",
    //         "features": [
    //             {
    //                 "id": 2,
    //                 "name": "Floral Castings",
    //                 "description": "The cast iron parts have floral design patterns."
    //             },
    //             {
    //                 "id": 3,
    //                 "name": "Knob on Body",
    //                 "description": "The front knob for the plane is attached to the front portion of the main plane casting."
    //             },
    //             {
    //                 "id": 5,
    //                 "name": "Slotted Brass Thumbscrews",
    //                 "description": "The thumb screws are made from brass with knurled round heads.  They have flat head screw slots cut in them."
    //             },
    //             {
    //                 "id": 6,
    //                 "name": "Stanley 45 Cast Fence",
    //                 "description": "The fence has '45' and 'Stanley' cast in to it at the toe and heal, respectively."
    //             },
    //             {
    //                 "id": 7,
    //                 "name": "Fence Flat Spot",
    //                 "description": "The fence has a flat spot cast in to the middle of the outward face of the fence.  This was useddone so that the fence could bef lipped around and used as a registration surface to extend the reach of the plane."
    //             },
    //             {
    //                 "id": 8,
    //                 "name": "Nickel Plating",
    //                 "description": "The cast iron and brass parts are finished with nickel plating."
    //             }
    //         ]
    //     },
    //     {
    //         "id": 5,
    //         "type": "5",
    //         "prodStart": 1893,
    //         "prodEnd": 1894,
    //         "weight": "",
    //         "hasImage": false,
    //         "description": "Fifth production type Stanley #45. Nickel plated plane and screws with floral castings and the knob on the main body. Added cutter depth adjustment mechanism.",
    //         "features": [
    //             {
    //                 "id": 2,
    //                 "name": "Floral Castings",
    //                 "description": "The cast iron parts have floral design patterns."
    //             },
    //             {
    //                 "id": 3,
    //                 "name": "Knob on Body",
    //                 "description": "The front knob for the plane is attached to the front portion of the main plane casting."
    //             },
    //             {
    //                 "id": 5,
    //                 "name": "Slotted Brass Thumbscrews",
    //                 "description": "The thumb screws are made from brass with knurled round heads.  They have flat head screw slots cut in them."
    //             },
    //             {
    //                 "id": 6,
    //                 "name": "Stanley 45 Cast Fence",
    //                 "description": "The fence has '45' and 'Stanley' cast in to it at the toe and heal, respectively."
    //             },
    //             {
    //                 "id": 7,
    //                 "name": "Fence Flat Spot",
    //                 "description": "The fence has a flat spot cast in to the middle of the outward face of the fence.  This was useddone so that the fence could bef lipped around and used as a registration surface to extend the reach of the plane."
    //             },
    //             {
    //                 "id": 8,
    //                 "name": "Nickel Plating",
    //                 "description": "The cast iron and brass parts are finished with nickel plating."
    //             },
    //             {
    //                 "id": 9,
    //                 "name": "Depth Adjuster",
    //                 "description": "There is a thumb wheel that is used to drive a screw with a perpenduclar pin on it for adjusting the cutting irons depth.  The corresponding plane irons will have a notch cout out of their right side (with the bevel down)."
    //             }
    //         ]
    //     },
    //     {
    //         "id": 6,
    //         "type": "6",
    //         "prodStart": 1895,
    //         "prodEnd": 1895,
    //         "weight": "",
    //         "hasImage": false,
    //         "description": "Sixth production type Stanley #45. Nickel plated plane and screws with floral castings and the knob on the main body. Added rosewood on fence.",
    //         "features": [
    //             {
    //                 "id": 2,
    //                 "name": "Floral Castings",
    //                 "description": "The cast iron parts have floral design patterns."
    //             },
    //             {
    //                 "id": 3,
    //                 "name": "Knob on Body",
    //                 "description": "The front knob for the plane is attached to the front portion of the main plane casting."
    //             },
    //             {
    //                 "id": 5,
    //                 "name": "Slotted Brass Thumbscrews",
    //                 "description": "The thumb screws are made from brass with knurled round heads.  They have flat head screw slots cut in them."
    //             },
    //             {
    //                 "id": 6,
    //                 "name": "Stanley 45 Cast Fence",
    //                 "description": "The fence has '45' and 'Stanley' cast in to it at the toe and heal, respectively."
    //             },
    //             {
    //                 "id": 7,
    //                 "name": "Fence Flat Spot",
    //                 "description": "The fence has a flat spot cast in to the middle of the outward face of the fence.  This was useddone so that the fence could bef lipped around and used as a registration surface to extend the reach of the plane."
    //             },
    //             {
    //                 "id": 8,
    //                 "name": "Nickel Plating",
    //                 "description": "The cast iron and brass parts are finished with nickel plating."
    //             },
    //             {
    //                 "id": 9,
    //                 "name": "Depth Adjuster",
    //                 "description": "There is a thumb wheel that is used to drive a screw with a perpenduclar pin on it for adjusting the cutting irons depth.  The corresponding plane irons will have a notch cout out of their right side (with the bevel down)."
    //             },
    //             {
    //                 "id": 10,
    //                 "name": "Rosewood Fence",
    //                 "description": "There is a strip of wosewood now attached to the fence, with two screw holes in the cast iron fence for mounting."
    //             }
    //         ]
    //     },
    //     {
    //         "id": 7,
    //         "type": "7",
    //         "prodStart": 1895,
    //         "prodEnd": 1906,
    //         "weight": "",
    //         "hasImage": false,
    //         "description": "Seventh production type Stanley #45. Nickel plated plane and screws with floral castings and the knob on the fence body. Major design update, moving knob to fence.",
    //         "features": [
    //             {
    //                 "id": 2,
    //                 "name": "Floral Castings",
    //                 "description": "The cast iron parts have floral design patterns."
    //             },
    //             {
    //                 "id": 8,
    //                 "name": "Nickel Plating",
    //                 "description": "The cast iron and brass parts are finished with nickel plating."
    //             },
    //             {
    //                 "id": 9,
    //                 "name": "Depth Adjuster",
    //                 "description": "There is a thumb wheel that is used to drive a screw with a perpenduclar pin on it for adjusting the cutting irons depth.  The corresponding plane irons will have a notch cout out of their right side (with the bevel down)."
    //             },
    //             {
    //                 "id": 10,
    //                 "name": "Rosewood Fence",
    //                 "description": "There is a strip of wosewood now attached to the fence, with two screw holes in the cast iron fence for mounting."
    //             },
    //             {
    //                 "id": 11,
    //                 "name": "Knob on Fence",
    //                 "description": "The front knob for the plane is attached to the front portion of the fence casting."
    //             },
    //             {
    //                 "id": 12,
    //                 "name": "Threaded Knob",
    //                 "description": "The front knob is held in place by being threaded on to the fence directly with no other hardware."
    //             },
    //             {
    //                 "id": 13,
    //                 "name": "45 Oval Fence",
    //                 "description": "The fence has '45' cast in an oval in the center of the fence."
    //             },
    //             {
    //                 "id": 14,
    //                 "name": "Flat Thumbscrews",
    //                 "description": "The thumbscrews are flat with dished faces and no longer round knurled heads. These are also nickel plated."
    //             }
    //         ]
    //     },
    //     {
    //         "id": 8,
    //         "type": "8",
    //         "prodStart": 1907,
    //         "prodEnd": 1908,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "",
    //         "features": [
    //             {
    //                 "id": 2,
    //                 "name": "Floral Castings",
    //                 "description": "The cast iron parts have floral design patterns."
    //             },
    //             {
    //                 "id": 8,
    //                 "name": "Nickel Plating",
    //                 "description": "The cast iron and brass parts are finished with nickel plating."
    //             },
    //             {
    //                 "id": 9,
    //                 "name": "Depth Adjuster",
    //                 "description": "There is a thumb wheel that is used to drive a screw with a perpenduclar pin on it for adjusting the cutting irons depth.  The corresponding plane irons will have a notch cout out of their right side (with the bevel down)."
    //             },
    //             {
    //                 "id": 10,
    //                 "name": "Rosewood Fence",
    //                 "description": "There is a strip of wosewood now attached to the fence, with two screw holes in the cast iron fence for mounting."
    //             },
    //             {
    //                 "id": 11,
    //                 "name": "Knob on Fence",
    //                 "description": "The front knob for the plane is attached to the front portion of the fence casting."
    //             },
    //             {
    //                 "id": 12,
    //                 "name": "Threaded Knob",
    //                 "description": "The front knob is held in place by being threaded on to the fence directly with no other hardware."
    //             },
    //             {
    //                 "id": 14,
    //                 "name": "Flat Thumbscrews",
    //                 "description": "The thumbscrews are flat with dished faces and no longer round knurled heads. These are also nickel plated."
    //             },
    //             {
    //                 "id": 16,
    //                 "name": "'Trade 45 Mark' Fence",
    //                 "description": "The fence has 'TRADE 45 MARK' cast in to the middle of it."
    //             }
    //         ]
    //     },
    //     {
    //         "id": 9,
    //         "type": "9",
    //         "prodStart": 1908,
    //         "prodEnd": 1909,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "",
    //         "features": []
    //     },
    //     {
    //         "id": 10,
    //         "type": "10",
    //         "prodStart": 1909,
    //         "prodEnd": 1909,
    //         "weight": "",
    //         "hasImage": false,
    //         "description": "",
    //         "features": []
    //     },
    //     {
    //         "id": 11,
    //         "type": "11",
    //         "prodStart": 1910,
    //         "prodEnd": 1914,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "",
    //         "features": []
    //     },
    //     {
    //         "id": 12,
    //         "type": "12",
    //         "prodStart": 1915,
    //         "prodEnd": 1920,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "",
    //         "features": []
    //     },
    //     {
    //         "id": 13,
    //         "type": "13",
    //         "prodStart": 1921,
    //         "prodEnd": 1921,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "",
    //         "features": []
    //     },
    //     {
    //         "id": 14,
    //         "type": "14",
    //         "prodStart": 1922,
    //         "prodEnd": 1922,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "",
    //         "features": []
    //     },
    //     {
    //         "id": 15,
    //         "type": "15",
    //         "prodStart": 1923,
    //         "prodEnd": 1935,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "",
    //         "features": []
    //     },
    //     {
    //         "id": 16,
    //         "type": "16",
    //         "prodStart": 1936,
    //         "prodEnd": 1948,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "",
    //         "features": []
    //     },
    //     {
    //         "id": 17,
    //         "type": "17",
    //         "prodStart": 1948,
    //         "prodEnd": 1960,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "",
    //         "features": []
    //     },
    //     {
    //         "id": 18,
    //         "type": "18",
    //         "prodStart": 1961,
    //         "prodEnd": 1962,
    //         "weight": "",
    //         "hasImage": false,
    //         "description": "",
    //         "features": []
    //     },
    //     {
    //         "id": 19,
    //         "type": "19",
    //         "prodStart": 1915,
    //         "prodEnd": 1924,
    //         "weight": "",
    //         "hasImage": false,
    //         "description": "A cast aluminum version.  The main cast pieces are made from aluminum instead of cast iron.",
    //         "features": []
    //     },
    //     {
    //         "id": 20,
    //         "type": "19A",
    //         "prodStart": 1924,
    //         "prodEnd": 1934,
    //         "weight": "",
    //         "hasImage": false,
    //         "description": "A cast aluminum version.  The main cast pieces are made from aluminum instead of cast iron.  Added '45A' cast in place of '45' near the main depth stop.",
    //         "features": []
    //     },
    //     {
    //         "id": 21,
    //         "type": "20",
    //         "prodStart": 1922,
    //         "prodEnd": 1960,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "This type encompases all the types that had Made in Canada trademarks.  They may have features from several other types as a result.",
    //         "features": []
    //     },
    //     {
    //         "id": 22,
    //         "type": "21",
    //         "prodStart": 1938,
    //         "prodEnd": 1938,
    //         "weight": "",
    //         "hasImage": true,
    //         "description": "This type was mostly about the boxes they were in, but the planes themselves line up with Type 15.  They represent the export models with boxes marked '45E'.",
    //         "features": []
    //     }
    // ]`);

    //   private TYPE_DATA: TypeData[] = [
    //     {
    //       type: '1',
    //       prodStart: '1884',
    //       prodEnd: '1885',
    //       features: [
    //         'Black japanning',
    //         'Floral cast fence',
    //         'Solid brass thumb screws'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '2',
    //       prodStart: '1886',
    //       prodEnd: '1887',
    //       features: [
    //         'Black japanning',
    //         'Floral cast fence with Stanley 45 cast in it',
    //         'Slotted brass thumb screws'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '3',
    //       prodStart: '1887',
    //       prodEnd: '1888',
    //       features: [
    //         'Black japanning',
    //         'Stanley 45 cast in fece',
    //         'Flat spot on center of fence',
    //         'Slotted brass thumb screws'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '4',
    //       prodStart: '1890',
    //       prodEnd: '1892',
    //       features: [
    //         'Nickel plated',
    //         'Stanley 45 cast in fece',
    //         'Flat spot on center of fence',
    //         'Slotted nickel plated thumb screws'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '5',
    //       prodStart: '1893',
    //       prodEnd: '1894',
    //       features: [
    //         'Nickel plated',
    //         'Stanley 45 cast in fece',
    //         'Flat spot on center of fence',
    //         'Depth adjustment for cutting iron',
    //         'Slotted nickel plated thumb screws'
    //       ],
    //       weight: '',
    //       hasImage: false
    //     },
    //     {
    //       type: '6',
    //       prodStart: '1895',
    //       prodEnd: '1895',
    //       features: [
    //         'Nickel plated',
    //         'Stanley 45 cast in fece',
    //         'Flat spot on center of fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Slotted nickel plated thumb screws'
    //       ],
    //       weight: '',
    //       hasImage: false
    //     },
    //     {
    //       type: '7',
    //       prodStart: '1895',
    //       prodEnd: '1906',
    //       features: [
    //         'Nickel plated',
    //         'Floral body',
    //         '45 in oval on fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence'
    //       ],
    //       weight: '',
    //       hasImage: false
    //     },
    //     {
    //       type: '8',
    //       prodStart: '1907',
    //       prodEnd: '1908',
    //       features: [
    //         'Nickel plated',
    //         'Floral body',
    //         'TRADE 45 MARK on fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '9',
    //       prodStart: '1908',
    //       prodEnd: '1909',
    //       features: [
    //         'Nickel plated',
    //         'Stippled body',
    //         'TRADE 45 MARK on fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '10',
    //       prodStart: '1909',
    //       prodEnd: '1909',
    //       features: [
    //         'Nickel plated',
    //         'Stippled body',
    //         'TRADE 45 MARK on fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence',
    //         'Three line trademark - Stanley Rule & Level Co. Pat. Jan.22.95'
    //       ],
    //       weight: '',
    //       hasImage: false
    //     },
    //     {
    //       type: '11',
    //       prodStart: '1910',
    //       prodEnd: '1914',
    //       features: [
    //         'Nickel plated',
    //         'Stippled body',
    //         'TRADE 45 MARK on fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence',
    //         'Script style trademark'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '12',
    //       prodStart: '1915',
    //       prodEnd: '1920',
    //       features: [
    //         'Nickel plated',
    //         'Stippled body',
    //         'TRADE 45 MARK on fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence',
    //         'Script style trademark',
    //         'Two piece fence with micro adjustment',
    //         'Micro adjustment locking screw is above adjustment screw'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '13',
    //       prodStart: '1921',
    //       prodEnd: '1921',
    //       features: [
    //         'Nickel plated',
    //         'Stippled body',
    //         'TRADE 45 MARK on fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence',
    //         'Inset SW trademark with New Britain Conn. U.S.A.',
    //         'Two piece fence with micro adjustment',
    //         'Micro adjustment locking screw is on front rosewood fence rod'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '14',
    //       prodStart: '1922',
    //       prodEnd: '1922',
    //       features: [
    //         'Nickel plated',
    //         'Stippled body',
    //         'TRADE 45 MARK on fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence',
    //         'Inset SW trademark with Made in U.S.A.',
    //         'Two piece fence with micro adjustment',
    //         'Micro adjustment locking screw is on front rosewood fence rod'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '15',
    //       prodStart: '1923',
    //       prodEnd: '1935',
    //       features: [
    //         'Nickel plated',
    //         'Stippled body',
    //         'TRADE 45 MARK on fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence',
    //         'Adjacent SW trademark with Made in U.S.A.',
    //         'Two piece fence with micro adjustment',
    //         'Micro adjustment locking screw is on front rosewood fence rod'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '16',
    //       prodStart: '1936',
    //       prodEnd: '1948',
    //       features: [
    //         'Nickel plated',
    //         'Stippled body',
    //         'TRADE 45 MARK on fence',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence',
    //         'Stanley notched rectangle trademark with Made in U.S.A.',
    //         'Two piece fence with micro adjustment',
    //         'Micro adjustment locking screw is on front rosewood fence rod'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '17',
    //       prodStart: '1948',
    //       prodEnd: '1960',
    //       features: [
    //         'Nickel plated',
    //         'Stippled body',
    //         'TRADE 45 MARK on fence (Early models)',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence',
    //         'Stanley notched rectangle trademark with Made in U.S.A. (Early models)',
    //         'Stanley notched rectangle trademark with Made in U.S.A. and Trade-45-Mark (Late models)',
    //         'Two piece fence with micro adjustment',
    //         'Micro adjustment locking screw is on front rosewood fence rod',
    //         'Stanley notched rectangle gold foil stamped in handle (Late models)'
    //       ],
    //       weight: '',
    //       hasImage: true
    //     },
    //     {
    //       type: '18',
    //       prodStart: '1961',
    //       prodEnd: '1962*',
    //       features: [
    //         'Nickel plated',
    //         'Stippled body',
    //         'TRADE 45 MARK on fence (Early models)',
    //         'Rosewood attached to fence',
    //         'Depth adjustment for cutting iron',
    //         'Flat thumb screws',
    //         'Knob attached to fence',
    //         'Stanley notched rectangle trademark with Made in U.S.A. (Early models)',
    //         'Stanley notched rectangle trademark with Made in U.S.A. and Trade-45-Mark (Late models)',
    //         'Two piece fence with micro adjustment',
    //         'Micro adjustment locking screw is on front rosewood fence rod',
    //         'Hardwood knob and handle',
    //         '*Existance is debated, and thought to only be a catalogue listing and never in production'
    //       ],
    //       weight: '',
    //       hasImage: false
    //     }
    //   ];
}