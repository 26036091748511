<div class="header-container">
    <div class="header-row">
        <div class="logo-image">
            <a routerLink="/"><img src="./assets/Mosquito_Made_White.svg" alt="Mosquito Made Header Logo" /></a>
        </div>
        <div class="header-text">{{pageTitle$ | async}}</div>
        <div class="socials">
            <a href="https://shop.mosquitomade.com" alt="Mosquito Made Shop link" target="_blank"><mat-icon svgIcon="store"></mat-icon></a>
            <a href="https://www.youtube.com/@mosquitomade" alt="YouTube link" target="_blank"><mat-icon svgIcon="youtube"></mat-icon></a>
            <a href="https://www.instagram.com/mosquito_aka_chris/" alt="Instagram link" target="_blank"><mat-icon svgIcon="instagram"></mat-icon></a>
            <a href="https://www.facebook.com/mosquitomade" alt="Facebook link" target="_blank"><mat-icon svgIcon="facebook"></mat-icon></a>
            <a href="https://twitter.com/themodsquito" alt="Twitter link" target="_blank"><mat-icon svgIcon="twitter"></mat-icon></a>
        </div>
    </div>
    <div class="menu-row">
        <mat-icon svgIcon="menu" class="main-menu" [matMenuTriggerFor]="menu"></mat-icon>
        <mat-menu #menu="matMenu">
            <a href="https://shop.mosquitomade.com" mat-menu-item><mat-icon svgIcon="store"></mat-icon>Shop Mosquito Made Items</a>
            <a href="https://blog.mosquitomade.com" mat-menu-item><mat-icon svgIcon="book-open-variant"></mat-icon>Mosquito Made Blog</a>
            <a routerLink="/type-study" mat-menu-item><mat-icon svgIcon="view-list"></mat-icon>Stanley #45 Type Study</a>
        </mat-menu>
    </div>
</div>
<div class="progress-bar-row">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading$ | async"></mat-progress-bar>
</div>