import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public pageTitle$: BehaviorSubject<string> = new BehaviorSubject('Mosquito Made');
}
