import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { catchError, tap } from "rxjs";
import { UserService } from "src/app/type-study/services/user.service";
import { GetUser, SetUser, UserLogin, UserLoginComplete, UserLogout } from "./user.actions";

export class UserStateModel {
    public token: string | null = null;
    public userId: string | null = null;
    public user: any = null;
    public viewPreferences: any;
}

@State<UserStateModel>({
    name: 'userState',
    defaults: {
        token: null,
        userId: null,
        user: null,
        viewPreferences: {}
    }
})

@Injectable()
export class UserState {
    constructor(private userService: UserService, private store: Store) { }

    @Selector()
    static selectUserId(state: UserStateModel) {
        return state.userId;
    }

    @Selector()
    static selectUser(state: UserStateModel) {
        return state.user;
    }

    @Selector()
    static selectViewPreferences(state: UserStateModel) {
        return state.viewPreferences;
    }

    @Action(UserLogin)
    login(ctx: StateContext<UserStateModel>, { payload }: UserLogin) {
        console.log('Dispatched Login Action', payload);
        this.userService.login(payload.username, payload.password)
            .pipe(tap(() => {
                this.store.dispatch(new GetUser())
            }),
                catchError(error => error));
    }

    @Action(UserLogout)
    logout() {
        return this.userService.logout()
            .pipe(tap(message => {
                this.store.reset({});
            }),
                catchError(error => error)
            );
    }

    @Action(SetUser)
    setUser(ctx: StateContext<UserStateModel>, { payload }: SetUser) {
        ctx.patchState({ user: payload || null });
    }

    @Action(GetUser)
    getUser(ctx: StateContext<UserStateModel>,) {
        return this.userService.getUser()
            .pipe(tap(user => {
                return ctx.dispatch(new SetUser(user));
            }),
                catchError((error) => {
                    return ctx.dispatch(new SetUser());
                }));
    }
}
