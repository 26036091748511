import { User } from "../interfaces/User";

export class UserLogin {
    static readonly type = '[User] Login';
    constructor(public payload: { username: string, password: string }) { }
}

export class UserLoginComplete {
    static readonly type = '[User] Login Complete';
    constructor(public payload: { user: User }) { };
}

export class UserLogout {
    static readonly type = '[User] Logout';
}

export class GetUser {
    static readonly type = '[User] Get User';
}

export class SetUser {
    static readonly type = '[User] Set User';

    constructor(public payload?: User) { };
}