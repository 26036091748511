import { moveItemInArray } from "@angular/cdk/drag-drop";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { CompareTypeItem } from "../interfaces/CompareTypeItem";

import { TypeInfoService } from "../services/type-info.service";
import { AddTypeToCompare, ClearCompareList, RemoveTypeFromCompare, ReorderTypeCompareItem } from "./type-study.actions";

export class TypeStudyStateModel {
    public compareTypes: CompareTypeItem[] = [];
}

@State<TypeStudyStateModel>({
    name: 'typeStudyState',
    defaults: {
        compareTypes: []
    }
})

@Injectable()
export class TypeStudyState {
    constructor(private typeService: TypeInfoService, private store: Store) { }

    @Selector()
    static selectTypesToCompare(state: TypeStudyStateModel) {
        return state.compareTypes;
    }

    @Action(AddTypeToCompare)
    addTypeToCompare(ctx: StateContext<TypeStudyStateModel>, { payload }: AddTypeToCompare) {
        ctx.patchState({ compareTypes: ctx.getState().compareTypes.concat(payload) });
    }

    @Action(RemoveTypeFromCompare)
    removeTypeFromCompare(ctx: StateContext<TypeStudyStateModel>, { payload }: RemoveTypeFromCompare) {
        ctx.patchState({ compareTypes: ctx.getState().compareTypes.filter(type => type.type !== payload) });
    }

    @Action(ClearCompareList)
    clearCompareList(ctx: StateContext<TypeStudyStateModel>) {
        ctx.patchState({ compareTypes: [] });
    }

    @Action(ReorderTypeCompareItem)
    reorderTypeCompareItem(ctx: StateContext<TypeStudyStateModel>, { payload }: ReorderTypeCompareItem) {
        let tempList: CompareTypeItem[] = [...ctx.getState().compareTypes];
        moveItemInArray(tempList, payload.previousIndex, payload.currentIndex);

        ctx.patchState({ compareTypes: tempList});
    }


}
